import { ReactElement, useEffect, useRef } from "react";
import { checkEngineEnabled } from "../../utils/checkEnableEngine";
import { appAxios } from "../../utils/appAxios";
import { useSetSetting } from "../../utils/setting";
import { useAtom } from "jotai";
import { isGoogleSSOAtom } from "../../store";
import { LoginErrorCode } from "../../types";
import { useGetAccessToken, useSaveTokenToStorage } from "../../utils/token";
import { Box } from "@chakra-ui/react";

declare global {
  interface Window {
    google: typeof import("google-one-tap");
  }
}

export type CredentialResponseType = {
  credential: string;
};

type GoogleLoginButtonProps = {
  onInvalidUserInfo: (errorCode: LoginErrorCode) => void;
};

export function GoogleLoginButton({
  onInvalidUserInfo,
}: GoogleLoginButtonProps): ReactElement {
  const buttonRef = useRef<HTMLDivElement>(null);
  const gClientId = import.meta.env.VITE_APP_GCLOUD_CLIENT_ID;

  const getAccessToken = useGetAccessToken();
  const saveToken = useSaveTokenToStorage();
  const setSettingInfo = useSetSetting();
  const [, setIsGoogleSSO] = useAtom(isGoogleSSOAtom);

  useEffect(() => {
    const handleCredentialResponse = async (
      credentialResponse: CredentialResponseType
    ): Promise<void> => {
      const sendData = {
        credential: credentialResponse.credential,
      };
      const token = await getAccessToken(
        onInvalidUserInfo,
        undefined,
        sendData
      );
      if (!token) {
        return;
      }
      if (!checkEngineEnabled(token.access_token)) {
        onInvalidUserInfo("ENGINE_DISABLED");
        return;
      }
      saveToken(token);
      const employee_info = await appAxios.get("/employee/me");
      if (employee_info) {
        setSettingInfo(employee_info.data.employee_id);
        setIsGoogleSSO(true);
      }
    };

    if (buttonRef.current && window.google) {
      window.google.accounts.id.initialize({
        client_id: gClientId,
        callback: handleCredentialResponse,
        ux_mode: "popup",
        context: "signin",
      });
    }

    if (buttonRef.current) {
      window.google.accounts.id.renderButton(buttonRef.current, {
        text: "signin_with",
        type: "standard",
        size: "large",
        theme: "outline",
        logo_alignment: "left",
      });
    }
  }, [
    gClientId,
    getAccessToken,
    onInvalidUserInfo,
    saveToken,
    setIsGoogleSSO,
    setSettingInfo,
  ]);
  return (
    <Box minH="100px">
      <div ref={buttonRef} />
    </Box>
  );
}
