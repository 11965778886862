import { atom } from "jotai";
import { atomWithStorage, createJSONStorage } from "jotai/utils";
import { MimosysEngine } from "./engines/mimosysEngine";
import {
  DEFAULT_DETECT_VOLUME,
  TOKEN_INFO_SESSION_STORAGE_KEY,
} from "./constants";
import { AudioRecorder } from "./utils/audioRecorder/audioRecorder";
import { MimosysDataAtom, MimosysDateType } from "./stores/mimosysStore";
import workletURL from "./assets/js/RecorderProcessor.js?url";
import { themeType } from "./types";

const DETECT_VOLUME_LOCALSTORAGE_KEY = "detectVolume";

export const detectVolumeRawAtom = atomWithStorage(
  DETECT_VOLUME_LOCALSTORAGE_KEY,
  NaN
);

export const themeColorAtom = atomWithStorage<themeType>(
  "themeColor",
  "sakura_light"
);

export const detectVolumeAtom = atom((get) => {
  const detectVolume = get(detectVolumeRawAtom);
  return isNaN(detectVolume) ? DEFAULT_DETECT_VOLUME : detectVolume;
});

export const detectedVolumeByPhraseVisualizerAtom = atom(false);

export const mi1EnginesAtom = atom([new MimosysEngine()]);

export type QuestionnaireAnswers = {
  state: "idle" | "skipped" | "responded";
  name: string;
  answers: Record<string, string>;
};
export const questionnaireAtom = atom<QuestionnaireAnswers>({
  state: "idle",
  name: "",
  answers: {},
});

export type AccessTokenInfo = {
  access_token: string;
  expired_at: string;
};
const accessTokenStorage = createJSONStorage<AccessTokenInfo | null>(
  () => sessionStorage
);
export const accessTokenInfoAtom = atomWithStorage<AccessTokenInfo | null>(
  TOKEN_INFO_SESSION_STORAGE_KEY,
  null,
  accessTokenStorage
);

export const audioRecorder = new AudioRecorder(workletURL);

// ログイン後のエンジンタイプのグローバル状態管理用
export type EngineTypesParams = {
  engine_type: string;
  options: Record<string, boolean>;
};

export type EngineTypes = "mi1" | "all";

export type DecodedAccessToken = {
  userId: string;
  userType: string;
  engineTypes?: Record<EngineTypes, EngineTypesParams>;
  companyId?: string;
  salesagentId?: string;
  companyEmail?: string;
  employeeEmail?: string;
  recType?: number;
};

export const DecodedAccessTokenInitial = {
  userId: "",
  userType: "",
};

export const DecodedAccessTokenAtom = atom<DecodedAccessToken>(
  DecodedAccessTokenInitial
);

// クエリログイン時のレスポンス「company_token」値保持用
export const companyAccessTokenAtom = atom("");

// クエリログインのグローバル状態管理用
export interface QueryLoginDataType {
  companyId: string;
  companyPassword: string;
  employeeId: string;
  recType?: number;
}

export interface QueryLoginPostDataType {
  companyId: string | undefined;
  companyAccessToken: string;
  employeeId: string | undefined;
  recType?: number;
}

export const isQueryModeAtom = atom(false);

export const queryLoginDataParamAtom = atom<
  QueryLoginDataType | QueryLoginPostDataType | undefined
>(undefined);

export const accessTokenAtom = atom("");

export const showLoginAlertDialogAtom = atom(false);

// STENスコアの出力有無のグローバル状態管理用
export const outputStenScoreAtom = atom(false);

// ログイン後のユーザー情報のグローバル状態管理用
export type EmployeeInfoType = {
  company_id: string;
  nickname?: string;
  employee_name: string;
  employee_id: string;
  level?: number;
  password_flag?: boolean;
  icon_color?: string;
  id?: string;
  delete_flag: boolean;
  locked: boolean;
};

export const employeeInfoInitial = {
  company_id: "",
  nickname: "",
  employee_name: "",
  employee_id: "",
  level: 0,
  icon_color: "ffffff",
  id: "",
  delete_flag: false,
  locked: false,
};

export const employeeInfoAtom = atom<EmployeeInfoType>(employeeInfoInitial);

export type EmployeeContractType = {
  privacy_policy: string;
  terms_of_service: string;
};

export const employeeContractInitial = {
  privacy_policy: "",
  terms_of_service: "",
};

export const employeeContractAtom = atom<EmployeeContractType>(
  employeeContractInitial
);

export type SplashImageInfoType = {
  splash_path: string;
  splash_no: number | null;
};

export const splashImageInfoInitial = {
  splash_path: "",
  splash_no: null,
};

export const splashImageInfoAtom = atom<SplashImageInfoType>(
  splashImageInfoInitial
);

export type LoginImageInfoType = {
  login_path: string;
  login_no: number | null;
};

export const loginImageInfoInitial = {
  login_path: "",
  login_no: null,
};

// ログイン画面に表示する画像のURLをlocalStorageに保持管理用
export const loginImagePathAtom = atomWithStorage("loginImagePathKey", "");
export const loginImageInfoAtom = atomWithStorage(
  "loginImageInfoKey",
  JSON.stringify(loginImageInfoInitial)
);

export type ApiUrl = {
  baseUrl: string;
};
export const ApiUrlInitial = {
  baseUrl: import.meta.env.VITE_BASE_API_URL,
};

export const apiUrlAtom = atom<ApiUrl>(ApiUrlInitial);

// 初回ログイン時のパスワード変更状態のグローバル状態管理用
export const passwordChangeStatusAtom = atom(false);

// GoogleSSO状態のグローバル状態管理用
export const isGoogleSSOAtom = atom(false);

// 既存の録音フレーズ情報（src/assets/json/phrases.json）、アプリ設定一括取得（/analysis/app/settings）のphrases（読み上げ文配列）とで共用定義
export type RawPhraseInfo = {
  titleKey: string;
  phraseKey: string;
  helperTextKey?: string;
  co1VoiceType?: string;
  limitSeconds?: number;
  dysphagiaVoiceTypes?: string;
  isRecordingRequired?: boolean;
};

// アプリ設定一括取得（/analysis/app/settings） → phrases（読み上げ文配列）のグローバル状態管理用
export const phrasesFromApiAtom = atom<Array<RawPhraseInfo>>([]);

// アプリ設定一括取得（/analysis/app/settings） → minimum_utterances（元気圧の計算に必要な最少発話数）のグローバル状態管理用
export const minimumUtterancesFromApiAtom = atom(0);

// アプリ設定一括取得（/analysis/app/settings） → mimosys_company_settings（契約者のMIMOSYS設定）のグローバル状態管理用
export interface RecType {
  rec_type: number;
  label: string;
}

export interface CompanySettingType {
  minimum_utterances: number;
  rec_retry_count: number;
  rec_types?: RecType[];
}

export const CompanySettingInitial: CompanySettingType = {
  minimum_utterances: 3,
  rec_retry_count: 0,
};

export const CompanySettingDataAtom = atom<CompanySettingType>(
  CompanySettingInitial
);

// rec_type（録音種別の選択情報）のグローバル状態管理用

function hasData(data: MimosysDateType[], key: string): boolean {
  for (const i in data) {
    if (key in data[i]) return true;
  }
  return false;
}

export const recTypeAtom = atom(0);

export const hasMi1ResultAtom = atom((get) => {
  const mimosysData = get(MimosysDataAtom);
  return hasData(mimosysData, "mi1_result");
});

export const hasMentalActivityAtom = atom((get) => {
  const mimosysData = get(MimosysDataAtom);
  return hasData(mimosysData, "mental_activity");
});

export const hasVitalityAtom = atom((get) => {
  const mimosysData = get(MimosysDataAtom);
  return hasData(mimosysData, "vitality");
});

export const footerIndexAtom = atom(0);

export type phraseVisualizerType = "Circle" | "Bar";

export const phraseVisualizerTypeAtom = atomWithStorage<phraseVisualizerType>(
  "phraseVisualizerTypeKey",
  "Circle"
);

export type LanguageLabelsType = {
  [language: string]: { [key: string]: string };
};

export const LanguageLabelsAtom = atom<LanguageLabelsType>({
  ja: {
    label_vitality: "元気圧",
    label_mental_activity: "心の活量値",
    label_mind_index: "こころ指数",
  },
  en: {
    label_vitality: "Vitality",
    label_mental_activity: "Mental Activity",
    label_mind_index: "Mind Index",
  },
});
